import { Component, createRef, LegacyRef, RefObject } from 'react';
import './LBSolverComponent.css';

type MyProps = any;
type MyState = 
{
    lettersToUse: string | undefined
    progressText: string | undefined
    loading: boolean
}

export class LBSolverComponent extends Component<MyProps, MyState>
{
    private newPlayerNameRef = createRef<HTMLInputElement>();

    constructor(props: MyProps)
    {
        super(props);
        this.state = {
            lettersToUse: "",
            progressText: "",
            loading: false
        };
        this.keyPressed = this.keyPressed.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);        
        this.getAnswers = this.getAnswers.bind(this);        
    }    

    handleSubmit(e: any)
    {
        e.preventDefault();
        if (this.newPlayerNameRef === undefined)
        {
            return;
        } 
        if (this.newPlayerNameRef.current === undefined)
        {
            return;
        }         
        if (this.newPlayerNameRef?.current?.value === undefined)
        {
            return;
        }         

        if (this.state.lettersToUse?.length === 16)
        {
            this.setState({ progressText: "Fetching..." });
            let temp = this.state.lettersToUse;
            this.setState({ lettersToUse: "" });
            this.newPlayerNameRef.current.value = "";
            this.getAnswers(temp);
        }
    }

    async getAnswers(lettersToUse: string)
    {
        const response = await fetch(`lbSolver?input='${lettersToUse}'`);
        const data = await response.text();
        this.setState({ progressText: data, loading: false });
    }    

    keyPressed(keyPressedArgs: any)
    {
        if (this.newPlayerNameRef === undefined)
        {
            return;
        } 
        if (this.newPlayerNameRef.current === undefined)
        {
            return;
        }         
        if (this.newPlayerNameRef?.current?.value === undefined)
        {
            return;
        }         
        var lettersToUse: string = "";

        let letters = this.newPlayerNameRef?.current?.value.toUpperCase();
        if (letters == undefined)
        {
            return;
        }

        var lastIndexAdded: number = 0;
        for (var i = 0; i < letters.length; i++)
        {
            var letter = letters[i];
            if (letter >= 'A' && letter <= 'Z')
            {
                if (!lettersToUse.includes(letter))                
                {
                    if (lettersToUse.length <= 15)
                    {
                        if (lastIndexAdded % 3 === 0)
                        {
                            lettersToUse += ' ';                    
                        }
                        lastIndexAdded++;                        
                        lettersToUse += letter;
                    }
                    this.newPlayerNameRef.current.value = lettersToUse;
                }
                else
                {
                    this.newPlayerNameRef.current.value = lettersToUse.slice(0, lettersToUse.length);
                }
            }
        }

        this.setState({ lettersToUse: lettersToUse });
    }

    inputField(ref: any)
    {
        return (
            <div className="indented">
                <p>Type in the 12 Letterboxed letters, going around such that each group of three represent one side of the box. Press Enter to get answers.</p>                        
                <form onSubmit={this.handleSubmit}>
                    <input type="text" ref={ref} className='inputTextArea' onKeyUp={this.keyPressed} />
                </form>
                <br/>
                <a>{this.state.lettersToUse}</a>
                <br />
                <div className="multiline">{this.state.progressText}</div>
            </div>
        );
    }

    render()
    {
        return this.inputField(this.newPlayerNameRef);
    }
}
