import React from 'react';
import './App.css';
import { LBSolverComponent } from './LBSolver/LBSolverComponent';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>Welcome to EdsonLand!</p>
        <LBSolverComponent />
      </header>
    </div>
  );
}

export default App;
